import createServiceFromResource from '@/classes/createService';
import createPromotable from '@/classes/order/createPromotable';
import dateFilter from '@/services/functions/date-filter';
import getObjectValueByPath from '@/services/functions/get-object-value-by-path';
import createUserFromResource from '@/classes/createUser';
import createPriceModification from '@/classes/createPriceModification';
import createPaymentRefund from '@/classes/order/createPaymentRefund';

export default function createOrderFromResource(data) {
  return {
    actions: {
      activable: data?.actions?.activable || false,
      approvable: data?.actions?.approvable || false,
      cancelable: data?.actions?.cancelable || false,
      completable: data?.actions?.completable || false,
      editable: data?.actions?.editable || false,
      pausable: data?.actions?.pausable || false,
      rejectable: data?.actions?.rejectable || false,
    },
    cancelReason: data.cancel_reason,
    cancelDescription: data.cancel_description,
    orderBalancePayments: (data?.order_balance_payments || []).map((payment) => ({
      amount: payment.amount,
      balanceType: payment.balance_type,
    })),
    approval: data.approval,
    autoRefillIsAllowed: data.auto_refill_is_allowed,
    cost: (data.cost || '').replace(/\s/g, ''),
    countries: data.countries,
    createdAt: dateFilter(data.created_at),
    completedAt: dateFilter(data.completed_at),
    currentQuantity: data.current_quantity,
    externalOrderStartCount: data.external_order_start_count,
    hasRefill: data.has_refill,
    id: data.id,
    marginPercent: data.margin_percent,
    priceModifications: (data.price_modifications || []).map(createPriceModification),
    name: data.name,
    percentOfCurrentFill: data.percent_of_current_fill,
    percentOfExternalStatisticsFill: data.percent_of_external_statistics_fill,
    price: data.price,
    promotable: data.promotable ? createPromotable(data.promotable) : {},
    providers: data.providers,
    quantity: data.quantity,
    isPaid: data.is_paid,
    quantityForFullFill: data.quantity_for_full_fill,
    refill: {
      firstStatistic: {
        date: dateFilter(getObjectValueByPath(data, 'refill.first_statistic.date')) || '',
        quantity: getObjectValueByPath(data, 'refill.first_statistic.quantity') || 0,
      },
      lastStatistic: {
        date: dateFilter(getObjectValueByPath(data, 'refill.last_statistic.date')) || '',
        quantity: getObjectValueByPath(data, 'refill.last_statistic.quantity') || 0,
      },
    },
    refillQuantity: data.refill_quantity ?? 0,
    refundAmount: data.refund_amount ?? 0,
    refundPayments: (data.refund_payments && data.refund_payments.map(createPaymentRefund)) ?? [],
    revenue: (data.revenue || '').replace(/\s/g, ''),
    service: createServiceFromResource(data.service),
    serviceId: data.service_id,
    serviceName: data.service_name,
    source: data.source,
    status: data.status,
    url: data.url ?? '',
    user: data.user ? createUserFromResource(data.user) : {},
    externalPanelOrders: data.external_panel_orders,
    hasNoActiveProviders: data.has_no_active_providers,
    targetableValues: data.targetable_values ?? [],
    comment: data.comment?.length ? data.comment : '',
    seoKeyword: data.seo_keyword?.length ? data.seo_keyword : [],
    keyword: data.keyword || '',
    pricingPer: data.pricing_per,
  };
}
